import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { Line, Bar } from "react-chartjs-2";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CSSTransition } from "react-transition-group";
import io from "socket.io-client";
import DialogueBox from "./DialogueBox";
import { useNavigate } from "react-router-dom";

import {
  Button,
  TextField,
  Box,
  Container,
  Paper,
  Typography,
  Chip,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  Autocomplete,
  TableCell,
  InputAdornment,
  Skeleton,
  Snackbar,
  Alert,
  CircularProgress,
  Grid,
} from "@mui/material";
import {
  GetApp as GetAppIcon,
  Refresh as RefreshIcon,
  Visibility as VisualizationIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  BarChart as BarChartIcon,
  Timeline as TimelineIcon,
} from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "chart.js/auto";
import ReactDOM from "react-dom";
import "../App.css";
import { StyledTableCell, StyledTableContainer } from "./SharedStyles";
import { useAgentColors } from "../contexts/AgentColorContext";
import { useAuth } from "../contexts/AuthContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Loader from "../app-component/Loader";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";

const ItemType = {
  COLUMN: "COLUMN",
};

const DraggableColumn = React.memo(
  ({ id, children, moveColumn, findColumn, frozen }) => {
    const [isHovered, setIsHovered] = useState(false);
    const originalIndex = findColumn(id).index;
    const [{ isDragging }, ref] = useDrag(
      {
        type: ItemType.COLUMN,
        item: { id, originalIndex },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
          const { id: droppedId, originalIndex } = item;
          const didDrop = monitor.didDrop();
          if (!didDrop) {
            moveColumn(droppedId, originalIndex);
          }
        },
      },
      [id, originalIndex, moveColumn, findColumn]
    );

    const [, drop] = useDrop(
      {
        accept: ItemType.COLUMN,
        hover({ id: draggedId }) {
          if (draggedId !== id) {
            const { index: overIndex } = findColumn(id);
            moveColumn(draggedId, overIndex);
          }
        },
      },
      [id, findColumn, moveColumn]
    );

    const opacity = isDragging ? 0 : 1;
    const refCallback = (node) => {
      if (!frozen) {
        ref(drop(node));
      }
    };

    return (
      <StyledTableCell
        ref={refCallback}
        style={{
          opacity,
          transition: "opacity 0.3s ease-in-out, outline 0.2s ease-in-out",
          height: "100%",
          position: "relative",
          outline: isHovered ? "1px solid white" : "none",
          outlineOffset: "-1px",
        }}
        className="column center-align"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
      </StyledTableCell>
    );
  }
);

const MemoizedLine = React.memo(Line);
const MemoizedBar = React.memo(Bar);

const StageCounts = ({ selectedAgents = [] }) => {
  const { agentColors, fetchAgentColors } = useAgentColors();
  const { user } = useAuth();
  const [stageLogs, setStageLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allAgents, setAllAgents] = useState([]);
  const [savedAgents, setSavedAgents] = useState([]);
  const [selectedAgentOptions, setSelectedAgentOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState("date");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isVisualizationOpen, setIsVisualizationOpen] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [chartType, setChartType] = useState("line");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [liveData, setLiveData] = useState(null);
  const [showFUBKEYDialog, setShowFUBKeyDialog] = useState(false);
  const showSnackbar = (message, severity = "info") => {
    setSnackbar({ open: true, message, severity });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    fetchAgentColors();
  }, [fetchAgentColors]);

  const fetchLiveData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      const selections = await axios.get(`${API_BASE_URL}/api/get-selections`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { agentIds, stageIds } = selections.data;
      if (stageIds.length === 0) {
        setError("Please Select Metrics To View Stage Logs"); // Set an error message
        showSnackbar("Stage IDs are empty. Please select a stage.", "error"); // Show snackbar
        return; // Exit the function early
      }

      const response = await axios.get(
        `${API_BASE_URL}/api/live-stage-counts`,
        {
          params: { agentIds, stageIds },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLiveData(response.data);
    } catch (error) {
      console.error("Error fetching live stage counts data:", error);
      setError("no data is available until a new selection is made.");
      showSnackbar(
        "Failed to fetch live stage counts data. Please try refreshing the page.",
        "error"
      );
    }
  }, []);

  useEffect(() => {
    fetchLiveData();
    const interval = setInterval(fetchLiveData, 60000);
    return () => clearInterval(interval);
  }, [fetchLiveData]);

  useEffect(() => {
    const socket = io(API_BASE_URL);
    socket.on("stageCounts", (updatedLiveData) => {
      setLiveData(updatedLiveData);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const fetchInitialColumnOrder = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const [columnOrderResponse, stagesResponse, selectionsResponse] =
          await Promise.all([
            axios.get(
              `${API_BASE_URL}/api/stage-column-order/get-column-order`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            ),
            axios.get(`${API_BASE_URL}/api/stages`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get(`${API_BASE_URL}/api/get-selections`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
          ]);

        const { order: savedOrder, labels: savedLabels } =
          columnOrderResponse.data;

        const stageMap = stagesResponse.data.reduce((acc, stage) => {
          acc[stage.id] = stage.name;
          return acc;
        }, {});
        const { stageIds } = selectionsResponse.data;

        const stageColumns = stageIds.map((id) => ({
          id: id.toString(),
          label: savedLabels?.[id] || stageMap[id] || `Stage ${id}`,
        }));

        let defaultColumns = [
          { id: "date", label: savedLabels?.date || "Date", isFrozen: true },
          {
            id: "agentName",
            label: savedLabels?.agentName || "Agent's Name",
            isFrozen: true,
          },
          ...stageColumns,
          { id: "total", label: savedLabels?.total || "Total", isFrozen: true },
        ];

        let finalColumns;
        if (Array.isArray(savedOrder) && savedOrder.length > 0) {
          finalColumns = savedOrder
            .map((id) => defaultColumns.find((col) => col.id === id))
            .filter(Boolean);

          const missingColumns = defaultColumns.filter(
            (col) => !finalColumns.some((savedCol) => savedCol.id === col.id)
          );
          finalColumns = [...finalColumns, ...missingColumns];
        } else {
          finalColumns = defaultColumns;
        }

        setColumns(finalColumns);
      } catch (error) {
        console.error("Error fetching initial column order:", error);
        setError(
          "Failed to fetch column order. Please try refreshing the page."
        );
      }
    };

    fetchInitialColumnOrder();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const stageLogsResponse = await axios.get(
          `${API_BASE_URL}/api/stage-logs`,
          // "http://localhost:3004",
          {
            params: { startDate, endDate },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Fetched stage logs:", stageLogsResponse.data);
        setStageLogs(stageLogsResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch stage logs. Please try refreshing the page.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axios.get(`${API_BASE_URL}/api/users`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const sortedAgents = Object.entries(response.data).sort(
          ([, a], [, b]) => a.localeCompare(b)
        );
        const agentOptions = sortedAgents.map(([id, name]) => ({
          value: id,
          label: name,
        }));
        console.log("Fetched agents:", agentOptions);
        setAllAgents(agentOptions);
      } catch (error) {
        console.error("Error fetching agents:", error);
        setError("Failed to fetch agents. Please try refreshing the page.");
      }
    };

    fetchAgents();
  }, [refreshing]);

  useEffect(() => {
    const fetchSavedAgents = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axios.get(`${API_BASE_URL}/api/get-selections`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const agentIds = response.data.agentIds || [];
        const savedAgentOptions = allAgents.filter((agent) =>
          agentIds.includes(agent.value)
        );
        console.log("Fetched saved agents:", savedAgentOptions);
        setSavedAgents(savedAgentOptions);
      } catch (error) {
        console.error("Error fetching saved agents:", error);
        setError(
          "Failed to fetch saved agents. Please try refreshing the page."
        );
      }
    };

    if (allAgents.length > 0) {
      fetchSavedAgents();
    }
  }, [allAgents, refreshing]);

  const saveColumnOrder = async (newColumns) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      const order = newColumns.map((col) => col.id);
      const labels = newColumns.reduce((acc, col) => {
        acc[col.id] = col.label;
        return acc;
      }, {});

      await axios.post(
        `${API_BASE_URL}/api/stage-column-order/save-column-order`,
        {
          order,
          labels,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error) {
      console.error("Error saving column order:", error);
      setError("Failed to save column order. Please try again.");
    }
  };

  const moveColumn = useCallback(
    (draggedId, overIndex) => {
      const draggedIndex = columns.findIndex((col) => col.id === draggedId);
      const newColumns = [...columns];
      const [movedColumn] = newColumns.splice(draggedIndex, 1);
      newColumns.splice(overIndex, 0, movedColumn);
      setColumns(newColumns);
      saveColumnOrder(newColumns);
    },
    [columns, saveColumnOrder]
  );

  const findColumn = useCallback(
    (id) => {
      const column = columns.find((col) => col.id === id);
      return {
        column,
        index: columns.indexOf(column),
      };
    },
    [columns]
  );

  const handleClearLogs = async () => {
    setModalIsOpen(false);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }

      await axios.delete(`${API_BASE_URL}/api/stage-logs`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStageLogs([]);
      showSnackbar("All stage logs have been cleared", "success");
    } catch (error) {
      console.error("Error clearing stage logs:", error);
      showSnackbar("Failed to clear stage logs. Please try again.", "error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortColumn = (columnId) => {
    if (sortColumn === columnId) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnId);
      setSortDirection("asc");
    }
  };

  const handleRefresh = () => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  };

  const processStageLogs = useCallback(() => {
    if (!Array.isArray(stageLogs) || stageLogs.length === 0) {
      return { filteredLogs: [], paginatedLogs: [], sortedDates: [] };
    }

    // First, filter the logs based on date range, selected agents, and search term
    let filteredLogs = stageLogs.filter((log) => {
      if (
        !log ||
        typeof log.date === "undefined" ||
        typeof log.agentId === "undefined"
      ) {
        return false;
      }

      const logDate = new Date(log.date);
      // const isWithinDateRange =
      //   (!startDate || logDate >= startDate) &&
      //   (!endDate || logDate <= new Date(endDate).setHours(23, 59, 59, 999));

      const isWithinDateRange =
        (!startDate || logDate >= new Date(startDate).setHours(0, 0, 0, 0)) &&
        (!endDate || logDate <= new Date(endDate).setHours(23, 59, 59, 999));

      const isSelectedAgent =
        selectedAgentOptions.length > 0
          ? selectedAgentOptions.some((agent) => agent.label === log.agentName)
          : savedAgents.some((agent) => agent.label === log.agentName);

      const matchesSearch =
        searchTerm === "" ||
        [
          new Date(log.date).toLocaleDateString(),
          log.agentName,
          log.total.toString(),
          ...Object.values(log.stageCounts).map((count) => count.toString()),
        ].some((value) =>
          value.toLowerCase().includes(searchTerm.toLowerCase())
        );

      return isWithinDateRange && isSelectedAgent && matchesSearch;
    });

    // Group logs by date and agent name
    const groupedLogs = filteredLogs.reduce((acc, log) => {
      const date = new Date(log.date).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = {};
      }
      if (
        !acc[date][log.agentName] ||
        new Date(log.date) > new Date(acc[date][log.agentName].date)
      ) {
        acc[date][log.agentName] = log;
      }
      return acc;
    }, {});

    // Flatten the grouped logs and sort by date (descending) and then by agent name (ascending)
    let finalLogs = Object.entries(groupedLogs)
      .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
      .flatMap(([date, agentLogs]) =>
        Object.values(agentLogs).sort((a, b) =>
          a.agentName.localeCompare(b.agentName)
        )
      );

    const paginatedLogs = finalLogs.slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );

    const sortedDates = [
      ...new Set(
        finalLogs.map((log) => new Date(log.date).toLocaleDateString())
      ),
    ].sort((a, b) => new Date(b) - new Date(a));

    return { filteredLogs: finalLogs, paginatedLogs, sortedDates };
  }, [
    stageLogs,
    startDate,
    endDate,
    selectedAgentOptions,
    savedAgents,
    searchTerm,
    page,
    rowsPerPage,
  ]);

  const { filteredLogs, paginatedLogs, sortedDates } = useMemo(
    () => processStageLogs(),
    [processStageLogs]
  );

  console.log("Paginated Logs---------------:", paginatedLogs);

  const getColorForAgent = useCallback(
    (agentName) => {
      if (!agentColors[agentName]) {
        return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
          Math.random() * 255
        )}, ${Math.floor(Math.random() * 255)}, 0.6)`;
      }
      return agentColors[agentName];
    },
    [agentColors]
  );

  const groupedData = useMemo(() => {
    return filteredLogs.reduce((acc, log) => {
      const date = new Date(log.date).toLocaleDateString();
      const agentName = log.agentName;
      if (!acc[agentName]) {
        acc[agentName] = {};
      }
      acc[agentName][date] = log.total;
      return acc;
    }, {});
  }, [filteredLogs]);

  const data = useMemo(
    () => ({
      labels: sortedDates,
      datasets: Object.keys(groupedData)
        .sort((a, b) => a.localeCompare(b))
        .map((agent) => ({
          label: agent,
          data: sortedDates.map((date) => groupedData[agent][date] || 0),
          backgroundColor: getColorForAgent(agent),
          borderColor: getColorForAgent(agent)?.replace("0.6", "1"),
          borderWidth: 1,
          fill: false,
          spanGaps: true,
        })),
    }),
    [sortedDates, groupedData, getColorForAgent]
  );

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              return `${context.dataset.label}: ${context.parsed.y}`;
            },
          },
        },
        legend: {
          position: "top",
          labels: {
            font: {
              size: 14,
            },
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Date",
            font: {
              size: 16,
            },
          },
          ticks: {
            font: {
              size: 14,
            },
          },
        },
        y: {
          title: {
            display: true,
            text: "Total Contacts in Stages",
            font: {
              size: 16,
            },
          },
          ticks: {
            font: {
              size: 14,
            },
          },
        },
      },
    }),
    []
  );

  const handleExportChart = async () => {
    const chartElement = document.getElementById("chart-container");
    const canvas = await html2canvas(chartElement);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 0, 0);
    pdf.save("stage_counts_chart.pdf");
  };

  const SkeletonLoader = () => (
    <Box sx={{ width: "100%" }}>
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} animation="wave" height={50} sx={{ my: 1 }} />
      ))}
    </Box>
  );
  useEffect(() => {
    if (user) {
      // Check if the user has an active subscription but no fubApiKey
      if (user.subscriptionStatus === "active" && !user.fubApiKey) {
        console.log(user, user.subscriptionStatus, user?.fubApiKey);
        console.log(showFUBKEYDialog);
        setShowFUBKeyDialog(true);
      }
      // Check for counts only if fubApiKey exists
      // else if (user.fubApiKey) {
      //   const persistedCounts = localStorage.getItem(`counts-${user.id}`); // Ensure to use user.id or user.userId

      //   if (!persistedCounts) {
      //     // Show an error message if counts do not exist
      //     setError("Please make selection to view stage logs.");
      //     // return <DialogueBox />;
      //   }
      // }
    }
  }, [user]); // Add user as a dependency
  // if (error) {
  //   return (
  //     <Container
  //       maxWidth="xl"
  //       style={{ padding: "250px", marginTop: "20px", textAlign: "center" }}
  //     >
  //       <Typography color="error" variant="h3" gutterBottom>
  //         {error}
  //       </Typography>
  //     </Container>
  //   );
  // }
  if (error) {
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>;
  }

  if (loading) {
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>;
  }

  return (
    <>
      {showFUBKEYDialog ? (
        <DialogueBox />
      ) : loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          gap={2}
          alignItems="center"
          height="100vh"
        >
          <Typography variant="body1" color="error" gutterBottom align="center">
            {/* Select Metric to view this Stage Logs */}
            {error}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
          >
            Back to Tracking Configuration
          </Button>
        </Box>
      ) : (
        <DndProvider backend={HTML5Backend}>
          <Container
            maxWidth="xl"
            style={{ padding: "20px", marginTop: "20px" }}
          >
            {error && (
              // loading ? <Loader/> : ()
              <Typography color="error" variant="h6" gutterBottom>
                {error}
              </Typography>
            )}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h4" component="h1" gutterBottom>
                Stage Tracking
              </Typography>
              <Box display="flex" gap={2}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setModalIsOpen(true)}
                  startIcon={<DeleteIcon />}
                >
                  Clear Logs
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GetAppIcon />}
                >
                  <CSVLink
                    data={filteredLogs.map((log) => ({
                      date: new Date(log.date).toLocaleDateString(),
                      agentName: log.agentName,
                      ...log.stageCounts,
                      total: log.total,
                    }))}
                    filename="stage_logs.csv"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Export CSV
                  </CSVLink>
                </Button>
                <Tooltip title="Refresh">
                  <IconButton onClick={handleRefresh} disabled={refreshing}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Visualization">
                  <IconButton onClick={() => setIsVisualizationOpen(true)}>
                    <VisualizationIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={2} gap={2}>
              <Box display="flex" gap={2}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  isClearable
                  customInput={
                    <TextField label="Start Date" variant="outlined" />
                  }
                  popperClassName="calendar-popper"
                  popperPlacement="top"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) =>
                    setEndDate(
                      date ? new Date(date.setHours(23, 59, 59, 999)) : null
                    )
                  }
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  isClearable
                  customInput={
                    <TextField label="End Date" variant="outlined" />
                  }
                  popperClassName="calendar-popper"
                  popperPlacement="top"
                />
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Autocomplete
              multiple
              options={savedAgents}
              value={selectedAgentOptions}
              onChange={(event, value) => setSelectedAgentOptions(value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.value}
                    label={option.label}
                    {...getTagProps({ index })}
                    onDelete={() => {
                      const newSelectedAgentOptions = [...selectedAgentOptions];
                      newSelectedAgentOptions.splice(index, 1);
                      setSelectedAgentOptions(newSelectedAgentOptions);
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select agents"
                  variant="outlined"
                />
              )}
            />

            {/* live Data  */}

            <Box mb={3} mt={2}>
              <Typography variant="h6" gutterBottom>
                Live Stage Counts
              </Typography>
              {liveData ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {liveData
                      .sort((a, b) => a.agentName.localeCompare(b.agentName)) // Sort alphabetically by agent name
                      .map((log) => (
                        <TableRow key={log.agentId}>
                          {columns.map((column) => (
                            <TableCell key={`${log.agentId}-${column.id}`}>
                              {column.id === "date"
                                ? new Date().toLocaleDateString() // Current date for live data
                                : column.id === "agentName"
                                ? log.agentName
                                : column.id === "total"
                                ? log.total
                                : log.stageCounts[column.id] || 0}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography>Loading live stage counts data...</Typography>
              )}
            </Box>

            {/* {loading && <SkeletonLoader />} */}
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Typography variant="h6">Stage Counts Overview</Typography>

                <StyledTableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <DraggableColumn
                            key={column.id}
                            id={column.id}
                            moveColumn={moveColumn}
                            findColumn={findColumn}
                          >
                            <Tooltip title={`Column: ${column.label}`}>
                              <div onClick={() => handleSortColumn(column.id)}>
                                {column.label}
                              </div>
                            </Tooltip>
                          </DraggableColumn>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedLogs.map((log) => (
                        <TableRow key={`${log.date}-${log.agentId}`} hover>
                          {columns.map((column) => (
                            <TableCell
                              key={`${log._id}-${column.id}`}
                              align="center"
                              sx={{
                                padding: "12px",
                                textAlign: "center",
                                height: "56px",
                                lineHeight: "56px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {column.id === "date"
                                ? new Date(log.date).toLocaleDateString()
                                : column.id === "agentName"
                                ? log.agentName
                                : column.id === "total"
                                ? log.total
                                : log.stageCounts[column.id] || 0}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </>
            )}
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={filteredLogs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {ReactDOM.createPortal(
              <div
                style={{
                  display: modalIsOpen ? "flex" : "none",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 9999,
                }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" component="h2" gutterBottom>
                    Are you sure you want to clear all stage logs?
                  </Typography>
                  <Box display="flex" justifyContent="space-around" mt={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClearLogs}
                    >
                      Yes, clear logs
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setModalIsOpen(false)}
                    >
                      No, keep logs
                    </Button>
                  </Box>
                </Paper>
              </div>,
              document.body
            )}
            {ReactDOM.createPortal(
              <CSSTransition
                in={isVisualizationOpen}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 9999,
                  }}
                >
                  <Paper
                    elevation={3}
                    style={{
                      padding: "20px",
                      width: "80vw",
                      height: "80vh",
                      overflow: "auto",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={() => setIsVisualizationOpen(false)}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography variant="h5" component="h2" gutterBottom>
                      Data Visualization
                    </Typography>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Box>
                        <IconButton onClick={() => setChartType("line")}>
                          <TimelineIcon
                            color={chartType === "line" ? "primary" : "inherit"}
                          />
                        </IconButton>
                        <IconButton onClick={() => setChartType("bar")}>
                          <BarChartIcon
                            color={chartType === "bar" ? "primary" : "inherit"}
                          />
                        </IconButton>
                      </Box>
                      <Button variant="contained" onClick={handleExportChart}>
                        Export Chart
                      </Button>
                    </Box>
                    <div
                      id="chart-container"
                      style={{
                        flexGrow: 1,
                        marginBottom: "20px",
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {chartType === "line" ? (
                        <MemoizedLine data={data} options={options} />
                      ) : (
                        <MemoizedBar data={data} options={options} />
                      )}
                    </div>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        onClick={() => setIsVisualizationOpen(false)}
                      >
                        Close
                      </Button>
                    </Box>
                  </Paper>
                </div>
              </CSSTransition>,
              document.body
            )}
            <Snackbar
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={closeSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={closeSnackbar}
                severity={snackbar.severity}
                sx={{ width: "100%" }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </Container>
        </DndProvider>
      )}
    </>
  );
};

export default StageCounts;
