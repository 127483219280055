import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ShowStepper from "../components/ShowStepper";

const ViewDetailDialogueBox = () => {
  const [open, setOpen] = useState(true); // Initially set to true to show the dialog

  // Handle close button
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open} // Correctly use the open state here
      // onClose={handleClose}
      onClose={(e, reason) => {
        // Prevent closing on backdrop click
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      // maxWidth="xl"
      // sx={{ maxWidth: "500px" }}
      aria-labelledby="responsive-dialog-title"
      // BackdropProps={{
      //   // Custom backdrop props
      //   // You can adjust as necessary
      //   onClick: (e) => {
      //     e.stopPropagation(); // Prevent the backdrop click from closing the dialog
      //   },
      // }}
    >
      <DialogTitle id="responsive-dialog-title">Lead Details</DialogTitle>
      <DialogContent>
        <ShowStepper />
      </DialogContent>

      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default ViewDetailDialogueBox;
