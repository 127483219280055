import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import axios from "axios";
import io from "socket.io-client";
import Cookies from "js-cookie";
import FilterLeadClaims from "./leadClaims/FilterLeadClaims";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { styled } from "@mui/material";
import ShowStepper from "./ShowStepper";
import ViewDetailDialogueBox from "../app-component/ViewDetailDialogueBox";
import { useAuth } from "../contexts/AuthContext";
import DialogueBox from "./DialogueBox";

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  fontSize: "1.1rem",
  minWidth: "180px",
  textTransform: "capitalize",
}));

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";

const LeadClaimDashboard = () => {
  const [claimEvents, setClaimEvents] = useState([]);
  const [showFUBKEYDialog, setShowFUBKeyDialog] = useState(false);
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showStepper, setShowStepper] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [open, setOpen] = useState(false); // For managing the dialog open state
  const [data, setData] = useState([]);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleViewDetailsClick = async (leadId) => {
    console.log(leadId, "leadId");
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/claim-events/${leadId}`
      );
      console.log("API response:", response.data);
      setSelectedLead(response.data); // Store selected lead's data
      setOpen(true); // Open the dialog
    } catch (error) {
      console.error("Error fetching claim events:", error);
      setError(`Failed to fetch claim events: ${error.message}`);
    }
  };
  const StyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1.5, 3),
    fontSize: "1.1rem",
    minWidth: "180px",
    textTransform: "capitalize",
  }));

  // const mergeAndSortEvents = useCallback((existingEvents, newEvents) => {
  //   console.log("Merging events:", { existingEvents, newEvents });
  //   const uniqueEventsMap = new Map();

  //   [...existingEvents, ...newEvents].forEach((event) => {
  //     event.history.forEach((historyItem) => {
  //       const eventKey = `${event.leadId}-${historyItem.eventTimestamp}-${historyItem.claimType}-${historyItem.claimedAt}`;
  //       if (
  //         !uniqueEventsMap.has(eventKey) ||
  //         new Date(historyItem.eventTimestamp) >
  //           new Date(uniqueEventsMap.get(eventKey).history[0].eventTimestamp)
  //       ) {
  //         uniqueEventsMap.set(eventKey, {
  //           ...event,
  //           history: [historyItem],
  //         });
  //       }
  //     });
  //   });

  //   const sortedEvents = Array.from(uniqueEventsMap.values()).sort((a, b) => {
  //     const aTimestamp = new Date(a.history[0].eventTimestamp).getTime();
  //     const bTimestamp = new Date(b.history[0].eventTimestamp).getTime();
  //     return bTimestamp - aTimestamp; // Newest first
  //   });

  //   console.log("Sorted events:", sortedEvents);
  //   return sortedEvents;
  // }, []);

  // const fetchData = useCallback(async () => {
  //   setLoading(true);
  //   setError(null);
  //   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  //   try {
  //     const response = await axios.get(
  //       `${API_BASE_URL}/api/claim-events?page=${currentPage}`
  //     );
  //     console.log("API response:", response.data);

  //     if (!response.data || !Array.isArray(response.data.events)) {
  //       throw new Error("Invalid data structure received from API");
  //     }

  //     setClaimEvents((prevEvents) =>
  //       mergeAndSortEvents(prevEvents, response.data.events)
  //     );
  //     setTotalPages(response.data.totalPages);
  //   } catch (error) {
  //     console.error("Error fetching claim events:", error);
  //     setError(`Failed to fetch claim events: ${error.message}`);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [currentPage, mergeAndSortEvents]);

  // Get claim events

  const mergeAndSortEvents = useCallback((existingEvents, newEvents) => {
    console.log("Merging events:", { existingEvents, newEvents });

    const uniqueEventsMap = new Map();

    [...existingEvents, ...newEvents].forEach((event) => {
      const eventKey = `${event.leadId}-${event.eventTimestamp}-${event.claimType}-${event.claimedAt}`;
      if (
        !uniqueEventsMap.has(eventKey) ||
        new Date(event.eventTimestamp) >
          new Date(uniqueEventsMap.get(eventKey).eventTimestamp)
      ) {
        uniqueEventsMap.set(eventKey, event);
      }
    });

    const sortedEvents = Array.from(uniqueEventsMap.values()).sort((a, b) => {
      const aTimestamp = new Date(a.eventTimestamp).getTime();
      const bTimestamp = new Date(b.eventTimestamp).getTime();
      return bTimestamp - aTimestamp; // Newest first
    });

    console.log("Sorted events:", sortedEvents);
    return sortedEvents;
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/claim-events/simplified?page=${currentPage}`
      );
      console.log("API response:", response.data);

      if (!response.data || !Array.isArray(response.data.events)) {
        throw new Error("Invalid data structure received from API");
      }

      setClaimEvents((prevEvents) =>
        mergeAndSortEvents(prevEvents, response.data.events)
      );
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching claim events:", error);
      setError(`Failed to fetch claim events: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [currentPage, mergeAndSortEvents]);

  useEffect(() => {
    fetchData();

    const socket = io(
      process.env.REACT_APP_BACKEND_URL || "http://localhost:3004"
    );

    socket.on("connect", () => {
      console.log("WebSocket connected");
    });

    socket.on("leadClaimed", (newEvent) => {
      console.log("Received leadClaimed event:", newEvent);

      setClaimEvents((prevEvents) => {
        const updatedEvent = {
          leadId: newEvent.leadId,
          currentState: {
            leadName: newEvent.leadName,
            claimSource: newEvent.claimSource,
            leadStage: newEvent.leadStage,
          },
          history: [
            {
              claimTypeDetails: newEvent.claimTypeDetails,
              claimedAt: newEvent.claimedAt,
              eventTimestamp: newEvent.eventTimestamp,
              claimType: newEvent.claimType,
              timeToClaim: newEvent.timeToClaim,
            },
          ],
        };

        return mergeAndSortEvents(prevEvents, [updatedEvent]);
      });
    });

    socket.on("disconnect", () => {
      console.log("WebSocket disconnected");
    });

    return () => {
      console.log("Disconnecting WebSocket");
      socket.disconnect();
    };
  }, [fetchData, mergeAndSortEvents]);

  useEffect(() => {
    console.log("Current claimEvents state:", claimEvents);
  }, [claimEvents]);

  const handleClose = () => {
    setOpen(false);
  };

  const loadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // handleViewDetailsClick

  // const handleViewDetailsClick = () => {
  //   console.log("View details clicked");
  //   setOpen(true);
  //   // Show the stepper when button is clicked
  // };

  const handleRefresh = () => {
    setCurrentPage(1);
    fetchData();
  };
  useEffect(() => {
    const fetchAgentPerformance = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/claim-events/agent-performance`
        );
        setData(response.data); // Assuming response.data is an array of agents' performance data
        console.log(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching agent performance:", err);
        setError("Failed to fetch agent performance");
        setLoading(false);
      }
    };

    fetchAgentPerformance();
  }, []);

  useEffect(() => {
    console.log("Current claimEvents state:", claimEvents);
  }, [claimEvents]);
  useEffect(() => {
    if (user && user.subscriptionStatus === "active" && !user.fubApiKey) {
      setShowFUBKeyDialog(true);
      // return;
    }
  }, [user]);
  // Handle close button

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {showFUBKEYDialog ? (
        <DialogueBox />
      ) : (
        <div>
          {/* <Button onClick={handleRefresh} disabled={loading}>
            Refresh Data
          </Button> */}
          <FilterLeadClaims />
          {/* Agent performance metrics  */}

          <Box p={4}>
            <Typography variant="h5" component="h5" gutterBottom>
              Agent Performance Metrics
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>AGENT</TableCell>
                    <TableCell>FTC CLAIMS</TableCell>
                    <TableCell>POND CLAIMS</TableCell>
                    <TableCell>REASSIGNED CLAIMS</TableCell>
                    <TableCell>RELEASE TO POND</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 ? (
                    data.map((agent, index) => (
                      <TableRow key={index}>
                        <TableCell>{agent.agentName}</TableCell>
                        <TableCell>{agent.ftcClaims.count}</TableCell>
                        <TableCell>{agent.pondClaims.count}</TableCell>
                        <TableCell>{agent.reassignedClaims.count}</TableCell>
                        <TableCell>{agent.releasedtopond.count}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>No claim events found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Lead Claims Overview  */}
          <Box p={4}>
            <Typography variant="h5" component="h5" gutterBottom>
              Lead Claims Overview
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>LEAD NAME</TableCell>
                    <TableCell>AGENT</TableCell>
                    <TableCell>CLAIM TYPE</TableCell>
                    <TableCell>POND</TableCell>
                    <TableCell>CLAIM DATE</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {claimEvents.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6}>No claim events found</TableCell>
                    </TableRow>
                  ) : (
                    claimEvents.map((event) => {
                      const uniqueKey = `${event.leadId}-${event.claimedAt}`;
                      return (
                        <TableRow key={uniqueKey}>
                          {/* Lead Name */}
                          <TableCell>{event.leadName || "Unknown"}</TableCell>
                          {/* Agent */}
                          <TableCell>
                            {event.claimTypeDetails?.claimedByName || "Unknown"}
                          </TableCell>
                          {/* Claim Type */}
                          <TableCell>
                            {event.claimType === "ReleasedToPond"
                              ? "Released To Pond"
                              : event.claimType === "FTC"
                              ? "First To Claim"
                              : event.claimType === "NoChange"
                              ? "No Change"
                              : "Unknown"}
                          </TableCell>

                          {/* Pond (Current assignment type) */}
                          <TableCell>
                            {event.newAssignment?.assignmentType || "Unknown"}
                          </TableCell>

                          {/* Claim Date */}
                          <TableCell>
                            {event.claimedAt
                              ? new Date(event.claimedAt)
                                  .toISOString()
                                  .slice(0, 10)
                              : "N/A"}
                          </TableCell>

                          {/* Action Button */}
                          <TableCell>
                            <StyledButton
                              variant="contained"
                              size="small"
                              onClick={() =>
                                handleViewDetailsClick(event.leadId)
                              } // Pass event to the handler
                            >
                              View Details
                            </StyledButton>
                            <Dialog
                              open={open} // Ensure this state is managed correctly
                              onClose={handleClose}
                              aria-labelledby="responsive-dialog-title"
                            >
                              <DialogTitle id="responsive-dialog-title">
                                Lead Details
                              </DialogTitle>
                              <DialogContent
                                sx={{ height: "200px", width: "500px" }}
                              >
                                <ShowStepper leadDetails={selectedLead} />{" "}
                                {/* Pass selected lead */}
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose} autoFocus>
                                  Close
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {loading && <CircularProgress />}
          {currentPage < totalPages && (
            <Button onClick={loadMore} disabled={loading}>
              Load More
            </Button>
          )}
        </div>
      )}
    </>

    // ------------------------------------
    // <div>
    //   <Button onClick={handleRefresh} disabled={loading}>
    //     Refresh Data
    //   </Button>
    //   <TableContainer component={Paper}>
    //     <Table>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>LEAD NAME</TableCell>
    //           <TableCell>CLAIM DETAILS</TableCell>
    //           <TableCell>CLAIMED AT</TableCell>
    //           <TableCell>EVENT TIMESTAMP</TableCell>
    //           <TableCell>SOURCE</TableCell>
    //           <TableCell>LEAD STAGE</TableCell>
    //           <TableCell>CLAIM TYPE</TableCell>
    //           <TableCell>TIME TO CLAIM (MS)</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {claimEvents.length === 0 ? (
    //           <TableRow>
    //             <TableCell colSpan={8}>No claim events found</TableCell>
    //           </TableRow>
    //         ) : (
    //           claimEvents.flatMap((event, eventIndex) =>
    //             event.history.map((historyItem, historyIndex) => {
    //               const uniqueKey = `${event.leadId}-${historyItem.eventTimestamp}-${eventIndex}-${historyIndex}`;
    //               return (
    //                 <TableRow key={uniqueKey}>
    //                   <TableCell>
    //                     {event.currentState?.leadName || "Unknown"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {historyItem.claimTypeDetails?.description ||
    //                       "No details"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {historyItem.claimedAt
    //                       ? new Date(historyItem.claimedAt).toLocaleString()
    //                       : "N/A"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {historyItem.eventTimestamp
    //                       ? new Date(
    //                           historyItem.eventTimestamp
    //                         ).toLocaleString()
    //                       : "N/A"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {event.currentState?.claimSource || "Unknown"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {event.currentState?.leadStage || "Unknown"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {historyItem.claimType || "Unknown"}
    //                   </TableCell>
    //                   <TableCell>{historyItem.timeToClaim || 0}</TableCell>
    //                 </TableRow>
    //               );
    //             })
    //           )
    //         )}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    //   {loading && <CircularProgress />}
    //   {currentPage < totalPages && (
    //     <Button onClick={loadMore} disabled={loading}>
    //       Load More
    //     </Button>
    //   )}
    // </div>
  );
};

export default LeadClaimDashboard;
//  hello world
