import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
} from "@mui/material";

const steps = [
  {
    label: "Released to buyer by Lee Adkins",

    // (
    //   <Box gap={2}>
    //     <Typography>Released to buyer by Lee Adkins</Typography>
    //     <Typography>2:29 am</Typography>
    //   </Box>
    // ),
  },
  {
    label: "Assigned to Lee Adkins  by Lee Adkins",
    // description: `4:30 am `,
  },
  {
    label: "Claimed By from buyer Lee adkins and assigned to Joey",
    // description: `8:30 pm `,
  },
];

const ShowStepper = ({ leadDetails }) => {
  console.log(leadDetails);
  // const [activeStep, setActiveStep] = React.useState(0);
  const recentHistory = leadDetails?.recentHistory || [];

  return (
    <Box>
      <Stepper orientation="vertical" activeStep={-1}>
        {recentHistory.map((historyItem, index) => (
          <Step key={historyItem._id}>
            <StepLabel>
              {`Claimed by ${historyItem.claimedByName} - ${historyItem.claimType}`}
            </StepLabel>
            <StepContent>
              <Typography variant="body2">
                {`Stage: ${historyItem.leadStage}`}
              </Typography>
              <Typography variant="body2">
                {`Claimed At: ${new Date(
                  historyItem.claimedAt
                ).toLocaleString()}`}
              </Typography>
              <Typography variant="body2">
                {`Previous Assignment: ${historyItem.previousAssignment.assignedToName}`}
              </Typography>
              <Typography variant="body2">
                {`New Assignment: ${historyItem.newAssignment.assignedToName}`}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ShowStepper;
